<template>
  <div>
    <div>
      <breadcrumb :breadcrumb_link="pinxpolicy_breadcrumb" />
    </div>

    <div class="header">
      <h1 class="overlay">POLICY</h1>
      <div class="headerimage" />
    </div>

    <div class="container contentDesc">
      <div class="title1">
        <h2>
          <strong>{{ $t('Policy_title') }}</strong>
        </h2>
      </div>
    </div>

    <div align="center" class="container" v-show="isLang === 0">
      <div class="flex justify-content-center w-100">
        <div id="btn-radios-2" class="categoryRadioGroup">
          <label class="cateBoxContainer">
            <input type="radio" value="개인정보 처리방침" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">개인정보 처리방침</div>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="이용약관" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">이용약관</div>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="윤리경영" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">윤리경영</div>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="이메일 무단 수집 거부" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">이메일 무단 수집 거부</div>
            </div>
          </label>
        </div>
      </div>
      <hr />
      <div class="container my-3">
        <Privacyinfo v-if="tab_name === '개인정보 처리방침'" />
      </div>
      <TOSpinx v-if="tab_name === '이용약관'" />
      <Ethicalmanagement v-if="tab_name === '윤리경영'" />
      <Rejectionemail v-if="tab_name === '이메일 무단 수집 거부'" />
    </div>

    <div align="center" class="container" v-show="isLang === 1">
      <div class="flex justify-content-center w-100">
        <div id="btn-radios-2" class="categoryRadioGroup">
          <label class="cateBoxContainer">
            <input type="radio" value="개인정보 처리방침" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">Privacy Policy</div>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="윤리경영" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">Ethical Management</div>
            </div>
          </label>
        </div>
      </div>
      <hr />
      <div class="container my-3">
        <Privacyinfo v-if="tab_name === '개인정보 처리방침'" />
      </div>
      <TOSpinx v-if="tab_name === '이용약관'" />
      <Ethicalmanagement v-if="tab_name === '윤리경영'" />
      <Rejectionemail v-if="tab_name === '이메일 무단 수집 거부'" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Privacyinfo from '@/pages/Footerlink/Components/Privacyinfo';
import TOSpinx from '@/pages/Footerlink/TOSpinx';
import Ethicalmanagement from '@/pages/Footerlink/Ethicalmanagement';
import Rejectionemail from '@/pages/Footerlink/Rejectionemail';

export default {
  name: 'Pinxpolicy',
  data() {
    return {
      pinxpolicy_breadcrumb: {
        toplink: 'HOME',
        prevlink: this.$t('breadcrum'),
        present: this.$t('breadcrum'),
      },
      tab_name: '개인정보 처리방침',
      isLang: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 서버로 접근로그를 저장한다. */
        console.log('index', to, from);
        this.refreshParams();
      }
    },
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  components: {Breadcrumb, Privacyinfo, TOSpinx, Ethicalmanagement, Rejectionemail},
  created() {
    this.refreshParams();
    this.refreshLocale(this.$i18n.locale);
  },
  methods: {
    refreshParams() {
      let index = this.$route.params.idx;
      console.log('index', index);
      if (index === '0') {
        this.tab_name = '개인정보 처리방침';
      }

      if (index === '1') {
        this.tab_name = '이용약관';
      }

      if (index === '2') {
        this.tab_name = '윤리경영';
      }

      if (index === '3') {
        this.tab_name = '이메일 무단 수집 거부';
      }
    },
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.categoryRadioGroup {
  width: 100%;
  height: 4rem;
  //border-bottom: 1px solid #e0e0e0;
}
.cateBoxContainer {
  width: 25%;
  height: 100%;
  border: 1px solid #b7b7b7;
  //height: 100%;
  margin: 0rem;
}

input[type='radio'] {
  display: none;
  & {
    + .cateBox {
      display: table;
      width: 100%;
      height: 100%;
      background-color: white;
      font-weight: bold;
      padding: 0.2rem;
      cursor: pointer;
      .innerText {
        display: table-cell;
        vertical-align: middle;
        margin-top: auto;
        margin-bottom: auto;
        align-self: center;
        word-break: keep-all;
      }
    }
  }
  &:checked {
    + .cateBox {
      background-color: #524a3d;
      color: white;
    }
  }
}

.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }

  .overlay {
    color: White;
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: lighter;
    letter-spacing: -0.1rem;
  }
}

.contentDesc {
  text-align: center;

  .title1 {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .title2 {
    margin-top: 3rem;
  }

  .title3 {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .headerimage {
    width: 100%;
    margin: 0;
    content: url('~@/assets/images/common/pinxpolicy_headerimage.jpg');
  }
  .innerText {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  .headerimage {
  }
  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }

    .overlay {
      padding-top: 5rem;
      color: White;
      position: absolute;
      text-align: center;
      z-index: 12;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;
      letter-spacing: -0.1rem;
    }
  }
  .innerText {
    font-size: 0.8rem;
  }
}
</style>

<i18n>
{
  "ko": {
    "breadcrum": "SK핀크스 정책",
    "Policy_title": "SK핀크스 정책"
  },
  "en": {
    "breadcrum": "SK Pinx Policy",
    "Policy_title": "SK Pinx Policy"
  },
  "ja": {
  }
}
</i18n>
